//
// Base styles
//

.alert {
 position: relative;
 padding: $alert-padding-y $alert-padding-x;
 margin-bottom: $alert-margin-bottom;
 border: $alert-border-width solid transparent;
 @include border-radius($alert-border-radius);
}

// Headings for larger alerts
.alert-heading {
 // Specified to prevent conflicts of changing $headings-color
 color: inherit;
}

// Provide class for links that match alerts
.alert-link {
 font-weight: $alert-link-font-weight;
}


// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
 padding-right: $alert-dismissible-padding-r;

 // Adjust close link position
 .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $stretched-link-z-index + 1;
  padding: $alert-padding-y * 1.25 $alert-padding-x;
 }
}


// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.

@each $state, $value in $theme-colors {
 $alert-background: shift-color($value, $alert-bg-scale);
 $alert-border: shift-color($value, $alert-border-scale);
 $alert-color: shift-color($value, $alert-color-scale);
 @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
  $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
 }
 .alert-#{$state} {
  @include alert-variant($alert-background, $alert-border, $alert-color);
 }
}
// scss-docs-end alert-modifiers
