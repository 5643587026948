.form-floating {
 position: relative;

 > .form-control,
 > .form-select {
  height: $form-floating-height;
  line-height: $form-floating-line-height;
 }

 > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; // allow textareas
  padding: $form-floating-padding-y $form-floating-padding-x;
  pointer-events: none;
  border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
  transform-origin: 0 0;
  @include transition($form-floating-transition);
 }

 // stylelint-disable no-duplicate-selectors
 > .form-control {
  padding: $form-floating-padding-y $form-floating-padding-x;

  &::placeholder {
   color: transparent;
  }

  &:focus,
  &:not(:placeholder-shown) {
   padding-top: $form-floating-input-padding-t;
   padding-bottom: $form-floating-input-padding-b;
  }
  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  &:-webkit-autofill {
   padding-top: $form-floating-input-padding-t;
   padding-bottom: $form-floating-input-padding-b;
  }
 }

 > .form-select {
  padding-top: $form-floating-input-padding-t;
  padding-bottom: $form-floating-input-padding-b;
 }

 > .form-control:focus,
 > .form-control:not(:placeholder-shown),
 > .form-select {
  ~ label {
   opacity: $form-floating-label-opacity;
   transform: $form-floating-label-transform;
  }
 }
 // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
 > .form-control:-webkit-autofill {
  ~ label {
   opacity: $form-floating-label-opacity;
   transform: $form-floating-label-transform;
  }
 }
 // stylelint-enable no-duplicate-selectors
}
