// transparent background and border properties included for button version.
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

.btn-close {
 box-sizing: content-box;
 width: $btn-close-width;
 height: $btn-close-height;
 padding: $btn-close-padding-y $btn-close-padding-x;
 color: $btn-close-color;
 background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
 border: 0; // for button elements
 @include border-radius();
 opacity: $btn-close-opacity;

 // Override <a>'s hover style
 &:hover {
  color: $btn-close-color;
  text-decoration: none;
  opacity: $btn-close-hover-opacity;
 }

 &:focus {
  outline: 0;
  box-shadow: $btn-close-focus-shadow;
  opacity: $btn-close-focus-opacity;
 }

 &:disabled,
 &.disabled {
  pointer-events: none;
  user-select: none;
  opacity: $btn-close-disabled-opacity;
 }
}

.btn-close-white {
 filter: $btn-close-white-filter;
}
