//
// Rotating border
//

// scss-docs-start spinner-border-keyframes
@keyframes spinner-border {
 to { transform: rotate(360deg) #{"/* rtl:ignore */"}; }
}
// scss-docs-end spinner-border-keyframes

.spinner-border {
 display: inline-block;
 width: $spinner-width;
 height: $spinner-height;
 vertical-align: $spinner-vertical-align;
 border: $spinner-border-width solid currentColor;
 border-right-color: transparent;
 // stylelint-disable-next-line property-disallowed-list
 border-radius: 50%;
 animation: $spinner-animation-speed linear infinite spinner-border;
}

.spinner-border-sm {
 width: $spinner-width-sm;
 height: $spinner-height-sm;
 border-width: $spinner-border-width-sm;
}

//
// Growing circle
//

// scss-docs-start spinner-grow-keyframes
@keyframes spinner-grow {
 0% {
  transform: scale(0);
 }
 50% {
  opacity: 1;
  transform: none;
 }
}
// scss-docs-end spinner-grow-keyframes

.spinner-grow {
 display: inline-block;
 width: $spinner-width;
 height: $spinner-height;
 vertical-align: $spinner-vertical-align;
 background-color: currentColor;
 // stylelint-disable-next-line property-disallowed-list
 border-radius: 50%;
 opacity: 0;
 animation: $spinner-animation-speed linear infinite spinner-grow;
}

.spinner-grow-sm {
 width: $spinner-width-sm;
 height: $spinner-height-sm;
}

@if $enable-reduced-motion {
 @media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
   animation-duration: $spinner-animation-speed * 2;
  }
 }
}
